import Keycloak from 'keycloak-js';
import Vue from 'vue';

const prdEnv = process.env.NODE_ENV === 'production',
  jsonPath = prdEnv ? './keycloak.json' : './static/keycloak.json',
  keycloak = new Keycloak(jsonPath);

const getSessionData = (key) => {
  const data = window.sessionStorage.getItem(key) === 'undefined';
  return data ? undefined : window.sessionStorage.getItem(key);
};

const setSessionData = (key, data) => {
  window.sessionStorage.setItem(key, data);
};

const tokenItem = getSessionData('token');
const idTokenItem = getSessionData('idToken');
const refreshTokenItem = getSessionData('refreshToken');

const sessionListeners = [];

const notifySessionExpired = () => {
  if (sessionListeners.length === 0) {
    window.logout();
  } else {
    sessionListeners.forEach((listener) => listener.onSessionExpired());
  }
};

const con = console;

const refreshToken = () => new Promise((resolve, reject) => {
  keycloak.updateToken(-1)
    .success(() => {
      resolve();
    }).error(() => {
      reject();
    });
});

const onResponse = (response) => {
  if (response.status === 401) {
    con.log('Sessão expirada mediante resposta 401.');
    notifySessionExpired();
  }
  return response;
};

const isRefreshTokenExpired = () => {
  const tenSeconds = 10 * 1000,
    now = new Date().getTime(),
    timeSkew = keycloak.timeSkew * 1000,
    refreshDate = new Date(keycloak.refreshTokenParsed.exp * 1000).getTime();

  let expiresIn = refreshDate;
  expiresIn += timeSkew;
  expiresIn -= now;
  expiresIn -= tenSeconds;

  return expiresIn < 0;
};

const setHeaders = (request) => {
  request.headers.set('Authorization', `Bearer ${keycloak.token}`);
  request.headers.set('Accept', 'application/json');
  request.headers.set('Content-type', 'application/json');
};

const isTokenExpired = () => keycloak.isTokenExpired(10);

export const auth = {
  loginRequired() {
    const promise = new Promise((resolve) => {
      keycloak.init({
        onLoad: 'login-required',
        timeSkew: 0,
        token: tokenItem,
        idToken: idTokenItem,
        refreshToken: refreshTokenItem,
        flow: 'standard',
        checkLoginIframe: false,
      })
        .success(() => {
          const { token } = keycloak;
          setSessionData('token', token);
          setSessionData('idToken', keycloak.idToken);
          setSessionData('refreshToken', keycloak.refreshToken);
          resolve();
        })
        .error((err) => {
          // Se o SSO nao for capaz de responder, utilizaremos
          // de uma abordagem otimista, entendendo que a sessao ainda pode
          // estar valida.
          resolve();
          console.warn(err); // eslint-disable-line
        });
    });
    return promise;
  },
  createInterceptors() {
    Vue.http.interceptors.push((request) => {
      if (!isTokenExpired()) {
        setHeaders(request);
      } else if (!isRefreshTokenExpired()) {
        const refreshTokenPromise = new Promise((resolve, reject) => refreshToken()
          .then(() => resolve())
          .catch(() => {
            con.log('Sessão expirada mediante expiração do token de refresh.');
            notifySessionExpired();
            reject();
          }));
        return refreshTokenPromise.then(() => {
          setHeaders(request);
          return Vue.http(request);
        });
      } else {
        con.log('Sessão expirada mediante expiração do token de refresh.');
        notifySessionExpired();
      }
      return onResponse;
    });
  },
  isTokenExpired() {
    return isTokenExpired();
  },
  refreshToken() {
    return new Promise((resolve) => {
      refreshToken().success(resolve).error(notifySessionExpired);
    });
  },
  registerSessionListener(listener) {
    if (listener.onSessionExpired) sessionListeners.push(listener);
  },
  doLogout() {
    window.logout();
  },
};

window.keycloak = keycloak;

const plugin = () => {
  window.logout = () => {
    keycloak.logout(window.location.origin);
  };
  Vue.prototype.$auth = keycloak;
};

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

export default plugin;
