<template>
  <div>
    <div style="text-align: center" class="title justify-center">
      <p class="mb-0">{{ titulo }}</p>
    </div>
    <v-container fluid grid-list-md class="DashboardPendencias_Apuracao">
      <v-data-iterator
        :items="dados"
        :server-items-length="totalPage"
        :options.sync="pagination"
        :hide-default-footer="escondePaginacao"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50],
        }">
        <template v-slot:default="props">
          <v-row align="start" justify="center">
            <v-col
              cols="12"
              class="pt-6"
              v-for="(item) in props.items"
              :key="item.idApuracao">
              <v-hover>
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 5 : 1}`" class="Card_Pendencia fill-height">
                  <v-row>
                    <v-col cols="10" class="pt-0">
                      <v-card-title class="Card_Title">
                        <v-container fluid>
                          <v-row justify="space-between">
                            <span>
                              {{ `${item.tipoAcao} #${item.idAcao} :: ${item.divisao}` }}
                            </span>
                          </v-row>
                        </v-container>
                      </v-card-title>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-row-reverse pr-5 pt-0">
                      <v-tooltip left >
                        <template v-slot:activator="{ on }">
                          <v-btn text icon v-on="on" @click.native="abrirDetalhamento(item)">
                            <v-icon >info</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $tc('label.detalhe', 2) }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                    <v-card-text class="Card_Content">
                      <v-container fluid>
                        <v-row justify="center" align="center">
                          <v-col cols="12" class="pa-0">
                            <span> {{ `${$t('title.apuracao', 1)} #${item.idApuracao}` }}</span>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <span> {{ `${$t('label.valor_total_apuracao')} ${getMoney(item.valorRecebimento)}` }}</span>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <span v-if="exibirBotaoExibicaoMultiplasNds(item)">{{ `${$tc('label.num_nota_debito', 1)}: ` }}</span>
                            <v-btn v-if="exibirBotaoExibicaoMultiplasNds(item)" icon class="mx-0" @click.stop="abrirModalExibicaoMultiplasNds(item)">
                              <v-icon>visibility</v-icon>
                            </v-btn>
                            <span v-else> {{ formataCampoNumNotaDebito(item.numNotasDebito) }}</span>
                            <v-btn
                              v-if="visualizaIconeModalNotaDebito(item.countNotasDebito) && !exibirBotaoExibicaoMultiplasNds(item)"
                              icon
                              class="mx-0"
                              @click.native="visualizarNotasDebito(item)">
                              <v-icon>visibility</v-icon>
                            </v-btn>
                          </v-col>
                        <v-col cols="12" class="pa-0">
                          <span v-if="item.grupoFornecedor">{{ item.grupoFornecedor }}</span>
                          <span v-else>{{ item.fornecedor }}</span>
                          <span v-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <!-- <v-col cols="12" md="7" class="pa-0">
                          <span>{{ `${item.dtaInicio} a ${item.dtaFim}` }}</span>
                        </v-col> -->
                        <v-col cols="12" md="12">
                          <v-spacer></v-spacer>
                          <apuracao-acao-fluxo
                            :key="item.idApuracao"
                            :id-apuracao="item.idApuracao"
                            :ind-justificativaAnalise="item.indJustificativaAnalise"
                            :ind-habilitaFluxo="item.indHabilitaFluxo"
                            :ind-habilitaEdicaoAprovacao="item.indHabilitaEdicaoAprovacao"
                            :acao="item.acao"
                            :id-acao="item.idAcao"
                            :status="item.status"
                            :pode-cancelar="item.podeCancelar"
                            :pode-reprovar="item.podeReprovar"
                            :is-fluxo-paralelo="item.isFluxoParalelo"
                            :vlr-conf-apuracao="item.vlrConfApuracao"
                            :exibir-acompanhamento="false"
                            @recarregar="buscarDados()"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>

    <modal-nota-debito
      ref="modalNotaDebito">
    </modal-nota-debito>

    <modal-exibicao-multiplas-nds
      ref="ModalExibicaoMultiplasNds">
    </modal-exibicao-multiplas-nds>
  </div>
</template>

<script>
import ApuracaoAcaoFluxo from '@/spa/apuracao-acao/ApuracaoAcaoFluxo';
import ModalNotaDebito from '@/produto/spa/apuracao-acao-lista/ModalNotaDebito';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { pendencias } from '../../common/resources/workflow-apuracao-acao';
import ModalExibicaoMultiplasNds from '../../shared-components/ModalExibicaoMultiplasNdsCamelCase';

export default {
  name: 'dash-inicial-pendencias-apuracao',
  components: {
    ApuracaoAcaoFluxo,
    ModalNotaDebito,
    ModalExibicaoMultiplasNds,
  },
  props: {
    filtros: Object,
    dashFornecedor: {
      type: Boolean,
      default: false,
    },
    dashCliente: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    titulo() {
      if (this.title) {
        return this.title;
      }
      return this.$tc('title.pendencia_apuracao', 2);
    },
  },
  data() {
    return {
      dados: [],
      escondePaginacao: true,
      pagination: {
        itemsPerPage: 5,
      },
      totalPage: 0,
      filtrosAplicados: this.filtros,

      realizouPrimeiraBusca: false,
      countNotasExibicao: 4,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.dados = [];
        this.buscarDados();
        setTimeout(() => {
          this.realizouPrimeiraBusca = true;
        });
      },
    },
    pagination: {
      handler() {
        if (this.realizouPrimeiraBusca) {
          this.buscarDados();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    getPercent,

    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      pendencias(params, this.$resource)
        .then((res) => {
          const { data } = res;
          if (!data.resposta.length && this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.buscarDados();
            return;
          }
          this.dados = data.resposta;
          this.dados.forEach((i) => {
            const { acao } = i;
            i.acao = acao;
            i.acao.idAcao = i.idAcao;
            i.acao.idApuracao = i.idApuracao;
            const desAtributos = i.desAtributos
              ? JSON.parse(i.desAtributos.value) : {};
            i.acao = {
              ...i.acao,
              ...desAtributos,
            };
          });
          this.totalPage = data.quantidadeRegistrosPagina;
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirDetalhamento(item) {
      this.$router.push({
        name: 'detalharAcaoVerbaVariavel',
        params: {
          id: item.idApuracao,
          origem: 'dashboard-inicial',
          idAcao: item.idAcao,
          from: 'dashboard-inicial',
        },
      });
    },
    formataCampoNumNotaDebito(numNotaDebito) {
      const ids = numNotaDebito.split(',');
      if (ids.length > this.countNotasExibicao) {
        const numsNotaDebito = `${ids.slice(0, 4).join(',')}...`;
        return `${this.$tc('label.nota_debito', 1)} ${numsNotaDebito}`;
      }
      return `${this.$tc('label.nota_debito', 1)} ${numNotaDebito}`;
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    visualizaIconeModalNotaDebito(countNotasDebito) {
      return countNotasDebito > this.countNotasExibicao;
    },
    exibirBotaoExibicaoMultiplasNds(item) {
      return item.qtdNds && item.qtdNds === 'MULTIPLAS' && item.listaMultiplasNds && item.listaMultiplasNds.length;
    },
    abrirModalExibicaoMultiplasNds(item) {
      this.$refs.ModalExibicaoMultiplasNds.open(item);
    },
  },
};
</script>
<style>
.DashboardPendencias_Apuracao {
  padding-top: 0px;
}
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Title {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Title span {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
}
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Content {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Content span {
  font-size: 14px;
}
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Title button span i,
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Title span,
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Content span,
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Content button span i {
  color: rgba(0,0,0,.70) !important;
}
.DashboardPendencias_Apuracao .Card_Pendencia .Card_Actions {
  padding-top: 0px;
}
</style>
