<template lang="html">
  <v-app-bar dark app color="primary" elevate-on-scroll clipped-right>
    <v-app-bar-nav-icon v-show="!hideMenu"
      name="toolbar-button-sidenav-menu"
      @click.native="toggleSidenav">
    </v-app-bar-nav-icon>

    <v-btn icon small @click="home" class="mx-3">
      <v-icon>home</v-icon>
    </v-btn>

    <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>

    <img src="../../produto/assets/img/logo.svg" alt="" class="Toolbar__Image--size">

    <v-toolbar-title class="white--text" v-if="$vuetify.breakpoint.mdAndUp && !hasTenants">
      <span class="ml-2">Tradelinks {{ title === 'Tradelinks' ? '' : `- ${title}` }}</span>
    </v-toolbar-title>

    <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>

    <tenant-menu v-slot="{on}" v-if="hasTenants" @TenantMenu_selecionarTenant="selecionarTenant">
      <v-btn v-on="on" dark color="primary" v-if="$vuetify.breakpoint.mdAndUp" class="elevation-0 ml-2">
        <b>Tradelinks {{ title === 'Tradelinks' ? '' : `- ${title}` }}</b>
        <v-icon right dark>keyboard_arrow_down</v-icon>
      </v-btn>
      <v-btn v-on="on" text dark icon v-else>
        <v-icon>business</v-icon>
      </v-btn>
    </tenant-menu>

    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

    <v-alert
      v-if="possuiAlertaManutencao && (isAlertaComunicacao || isAlertaManutencao)"
      v-model="exibeAlertaManutencao"
      dismissible
      type="warning"
      border="left"
      elevation="2"
      icon="construction"
      class="ma-1"
    >
      {{ textoAlertaManutencao }}
      <a v-if="isAlertaComunicacao" :href="link" @click="salvaUsuarioAcessoAlerta" target="_blank" rel="noopener noreferrer">
        {{ $t('label.saiba_mais') }}
      </a>
    </v-alert>

    <div class="text-center" v-if="possuiAlertaManutencao && (isAlertaComunicacao || isAlertaManutencao)">
      <v-btn
        v-if="!exibeAlertaManutencao"
        icon
        color="orange"
        @click="exibeAlertaManutencao = true"
      >
        <v-icon>
          announcement
        </v-icon>
      </v-btn>
    </div>

    <v-spacer v-if="possuiAlertaManutencao && (isAlertaComunicacao || isAlertaManutencao)"></v-spacer>

    <v-btn
        icon
      v-for="(option, index) in options"
      :key="index">
      <v-icon>{{ option.icon }}</v-icon>
    </v-btn>

    <usuario-toolbar :usuario="usuario" :mail="mail" :estrutura-usuario="estruturaUsuario" :perfil="perfil"></usuario-toolbar>
    <calendario-toolbar></calendario-toolbar>
    <badge></badge>
  </v-app-bar>
</template>
<script type="text/javascript">
import Badge from '@/produto/shared-components/badge/Badge';
import ManualSistema from '@/produto/shared-components/manual/ManualSistema';
import UsuarioToolbar from '@/produto/shared-components/toolbar/UsuarioToolbar';
import CalendarioToolbar from '@/produto/spa/calendario/CalendarioToolbar';
import TenantMenu from '@/produto/spa/tenant/TenantMenu';
import { mapGetters } from 'vuex';

export default {
  props: {
    hideMenu: {
      type: Boolean,
      default() { return false; },
    },
    title: {
      type: String,
      default() { return ''; },
    },
    options: {
      type: Array,
      default() { return []; },
    },
    usuario: String,
    mail: String,
    estruturaUsuario: String,
    indAlertaComunicacao: Boolean,
    indAlertaManutencao: Boolean,
    perfil: String,
    state: {
      type: String,
      default: '',
    },
    possuiAlertaManutencao: {
      type: Boolean,
      default() { return false; },
    },
    textoAlertaManutencao: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progress: 0,
      progressInterval: null,
      done: false,
      transition: true,
      exibeAlertaManutencao: true,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantIds',
    ]),
    isAlertaManutencao() {
      return this.indAlertaManutencao === true;
    },
    isAlertaComunicacao() {
      return this.indAlertaComunicacao === true;
    },
    hasTenants() {
      return this.getTenantIds.length > 1;
    },
  },
  components: {
    TenantMenu,
    UsuarioToolbar,
    Badge,
    CalendarioToolbar,
    ManualSistema,
  },
  methods: {
    selecionarTenant(tenantID) {
      this.$emit('Toolbar_selecionarTenant', tenantID);
    },
    toggleSidenav() {
      this.$emit('TOGGLE_SIDEBAR');
    },
    home() {
      this.$emit('RESET_SIDEBAR');
    },
    salvaUsuarioAcessoAlerta() {
      this.$emit('salvaUsuarioAcessoAlerta');
    },
  },
};
</script>
<style src="@/produto/shared-components/toolbar/Toolbar.scss" lang="scss"></style>
