import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('job', 'relatorios');

const magaluRelatorioActions = {
  buscarCategoria: { method: 'GET', url: `${basePath}/produto/filtro/categoria` },
  buscarFamilia: { method: 'GET', url: `${basePath}/produto/filtro/familia` },
  buscarComparativoNotas: { method: 'GET', url: `${basePath}/notas/comparativo` },
  buscarNotasIntegradasNaoProcessadas: { method: 'GET', url: `${basePath}/notas/buscar-notas-nao-processadas` },
};

export const countRelatorioProduto = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'produto/count', parametros).doGet();

export const countRelatorioProdutoReorg = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'produto/reorg/count', parametros).doGet();

export const countRelatorioLogAcoes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'log/acao/count', parametros).doGet();

export const countRelatorioDetalhamentoNd = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'detalhamento/nd/count', parametros).doGet();

export const countRelatorioUsuario = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'usuario/count', parametros).doGet();

export const countRelatorioLogFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'log/fornecedor/count', parametros).doGet();

export const countRelatorioLogUsuario = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'log/usuario/count', parametros).doGet();

export const countRelatorioBaseContratos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'base/contratos/count', parametros).doGet();

export const countRelatorioDadosCampanhas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'dados/campanhas/count', parametros).doGet();

export const countRelatorioNdMassivaEpoca = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nd/massiva/epoca/count', parametros).doGet();

export const countRelatorioHistoricoJobs = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'historico/jobs/count', parametros).doGet();

export const countRelatorioLogProduto = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'log/produto/count', parametros).doGet();

export const countRelatorioDetalhamentoRetroativo = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'retroativo/jobs/count', parametros).doGet();

export const countExportacaoNotasSellin = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'notas/sellin/count', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, magaluRelatorioActions);
