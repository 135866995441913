import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'contrato/cadastro');

const planejamentoContratoActions = {
  buscarTiposContrato: { method: 'GET', url: `${basePath}/campos/tipo_contrato` },
  buscarGrupoFornecedorContrato: { method: 'GET', url: `${basePath}/campos/{idContrato}/grupo_fornecedor` },
  salvar: { method: 'POST', url: basePath },
  atualizar: { method: 'PUT', url: basePath },
  finalizar: { method: 'PUT', url: `${basePath}/finalizar` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idContrato}/executarAnalise` },
  cancelarContrato: { method: 'PUT', url: `${basePath}/{idContrato}/cancelarContrato` },
  encerrarContrato: { method: 'PUT', url: `${basePath}/{idContrato}/{justificativa}/encerrarContrato` },
  alterarContratoSubstituicaoAguardandoEncerramento: { method: 'PUT', url: `${basePath}/{idContratoEncerramento}/{idContratoAtual}/alterarContratoSubstituicaoAguardandoEncerramento` },
  buscarValoresApuracoesContrato: { methods: 'GET', url: `${basePath}/buscarValoresApuracoesContrato` },
  verificarDadosExportacao: { methods: 'GET', url: `${basePath}/verificar-dados-exportacao` },
  salvarAcao: { method: 'POST', url: `${basePath}/{idContrato}/salvar-acao` },
  deletarAcao: { method: 'DELETE', url: `${basePath}/{idContrato}/{idAcao}` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelarAcao` },
  atualizarAcao: { method: 'PUT', url: `${basePath}/{idContrato}/atualizar-acao` },
  executarAnaliseAcao: { method: 'PUT', url: `${basePath}/{idContrato}/acao/{idAcao}/executar-analise` },
  atualizarValorBase: { method: 'PUT', url: `${basePath}/atualizar-valor-base` },
  buscarCamposComunsAcoes: { method: 'GET', url: `${basePath}/campos/campos-comuns-acoes` },
  buscarProdutos: { method: 'GET', url: `${basePath}/campos/produto` },
  buscarFornecedores: { method: 'GET', url: `${basePath}/campos/fornecedor` },
  buscarCategoria: { method: 'GET', url: `${basePath}/campos/categoria` },
  buscarFamilia: { method: 'GET', url: `${basePath}/campos/familia` },
  buscarListaDocumentosGerados: { method: 'GET', url: `${basePath}/campos/buscarListaDocumentosGerados` },
  verificaSeValidacaoDuplicidadeContratoHabilitada: { method: 'GET', url: `${basePath}/is-validacao-duplicidade-habilitada` },
  verificaSeOpcaoReprovacaoContratoHabilitada: { method: 'GET', url: `${basePath}/is-opcao-reprovacao-habilitada` },
  verificaDuplicidadeContrato: { method: 'GET', url: `${basePath}/verificar-duplicidade-contrato` },
  buscarProdutosCartaParceria: { method: 'POST', url: `${basePath}/campos/produto/carta-parceria` },
  alterarContratoRenovacao: { method: 'PUT', url: `${basePath}/{idContrato}/{justificativa}/{indDesabilitaRenovacao}/alterar-contrato-renovacao` },
  atualizarViews: { method: 'PUT', url: `${basePath}/atualizar-views` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoContratoActions);
